<template>
  <div class="payment-error">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="info-box info-box--icon info-box--error">
            <h1 class="info-box__header">
              Błąd w przetwarzaniu płatności.
            </h1>

            <p class="info-box__text">
              Skontaktuj się z nami:
              <a class="info-box__email" href="mailto:pomoc@fotoregiony.pl">
                pomoc@fotoregiony.pl
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentError',
  metaInfo: {
    title: 'Błąd w przetwarzaniu płatności',
  },
};
</script>
